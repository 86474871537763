<template>
  <Loading v-if="is_loading" />
  <b-row v-else-if="pageData" class="show-friend">
    <!-- right-side -->
    <b-col sm="12" md="9">
      <b-card
        body-class="pb-0"
        :img-src="filterImages(pageData.user.media, 'cover_image')"
        :img-alt="pageData.user.first_name"
        img-top
      >
        <b-avatar
          class="my-avatar"
          size="10rem"
          :img-alt="pageData.user.first_name"
          :src="filterImages(pageData.user.media, 'path')"
        />
        <b-card-title>
          {{ pageData.user.first_name }}
        </b-card-title>
        <b-row align-h="between">
          <b-col>
            <b-card-sub-title class="mt-0">
              {{ $t(pageData.user.user_type) }}
            </b-card-sub-title>
            <b-card-text v-if="pageData.eduyear">
              {{ pageData.eduyear.name }}
            </b-card-text>
          </b-col>
          <b-col
            cols="auto"
            v-if="$store.state.userData.userId !== pageData.user.type_id"
          >
            <b-button
              :disabled="is_loading"
              v-if="
                !pageData.user.is_friend_request_pending &&
                pageData.user.is_friend.status === false
              "
              @click="addFriendHandler"
              class="add-friend-btn mr-2"
              sm="mb-2"
            >
              {{ $t("g.AddFriend") }}
            </b-button>
            <b-button
              :disabled="is_loading"
              v-else-if="
                pageData.user.is_friend.status === false &&
                pageData.user.is_friend_request_pending
              "
              @click="cancelFriendHandler"
              class="add-friend-btn mr-2"
              sm="mb-2"
            >
              {{ $t("g.cancel_friend_request") }}
            </b-button>
            <b-button
              :disabled="is_loading"
              v-else-if="pageData.user.is_friend.status === true"
              @click="deleteFriendHandler"
              variant="light"
              class="mr-2"
              sm="mb-2"
            >
              {{ $t("g.delete_friend") }}
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <!-- Start Tab Header -->
        <b-col cols="12" nav-class="mb-0 wrap">
          <div class="tab-header-scrollable">
            <b-tabs v-model="activeTab" style="overflow-x: auto">
              <b-tab
                v-for="(tab, index) in tabs"
                :key="index"
                :title="$t(`g.${tab.title}`)"
              ></b-tab>
            </b-tabs>
          </div>
        </b-col>
        <!-- End Tab Header -->
      </b-card>

      <!-- Start Tab Body -->
      <b-card>
        <b-tabs v-model="activeTab" nav-class="d-none">
          <b-tab v-for="(tab, index) in tabs" :key="index">
            <h4 class="tab-body-title">
              {{ $t(`g.${tab.title}`) }}
            </h4>
            <hr />
            <about
              v-if="tab.title == 'About'"
              :aboutProps="pageData.user.about"
            />
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- End Tab Body -->
    </b-col>
    <!-- right-side -->

    <!-- left side -->
    <b-col sm="12" md="3">
      <b-card :title="$t('g.Communication')">
        <hr />
        <b-card-sub-title
          @click="$bvModal.show('bv-modal-report')"
          class="report-link mt-0"
        >
          {{ $t("g.ReportUser") }}
        </b-card-sub-title>
      </b-card>
      <profile-parents-card-component
        v-if="pageData.guardians"
        :parentsCardProps="pageData.guardians"
      />
      <friends-card-component
        v-if="pageData.user.friends"
        :friendsCardProps="pageData.user.friends"
      />
    </b-col>
    <!-- left side -->

    <!-- report component -->
    <ReportComponent
      :reportableId="user_id"
      :reportableType="pageData.user.user_type"
    />
    <!-- report component -->

    <!-- overlay loading -->
    <overlay-component :isLoading="overlay_loading" />
    <!-- overlay loading -->
  </b-row>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
import About from "@/components/profile/ProfileAboutComponent";
import ReportComponent from "@/components/shared/ReportComponent";
import OverlayComponent from "@/components/shared/OverlayComponent";
import FriendsCardComponent from "@/components/profile/FriendsCardComponent";
import ProfileParentsCardComponent from "@/components/profile/ProfileParentsCardComponent";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BCardSubTitle,
  BButton,
  BListGroupItem,
  BAvatar,
  BTabs,
  BTab,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BTabs,
    About,
    Loading,
    BButton,
    BAvatar,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroupItem,
    ReportComponent,
    OverlayComponent,
    FriendsCardComponent,
    ProfileParentsCardComponent,
  },
  data() {
    return {
      user_id: null,
      is_loading: false,
      overlay_loading: false,
      pageData: null,
      activeTab: 0,
      profileImgaePlaceholder: require("@/assets/images/placeholders/profileAvatar.png"),
      profileCoverPlaceholder: require("@/assets/images/placeholders/profileCover.png"),
      tabs: [{ title: "About" }],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.user_id = this.$route.params.id;
      this.getProfileData(this.$route.params.id);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    // toast method
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    // get user profile data
    async getProfileData(id) {
      try {
        this.is_loading = true;
        const res = await this.$http.get(`/student/student/${id}`);
        this.pageData = res.data.data;
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },

    // make friend request
    async addFriendHandler() {
      try {
        this.overlay_loading = true;
        const formData = new FormData();
        formData.append("receiver", this.pageData.user.id);
        await this.$http.post(`/student/friend-requests`, formData);
        this.makeToast(
          "success",
          this.$t("g.send.successBody"),
          this.$t("g.send.successTitle")
        );
        this.getProfileData(this.user_id);
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.overlay_loading = false;
      }
    },
    async deleteFriendHandler() {
      try {
        this.overlay_loading = true;
        await this.$http.delete(
          `/student/user-friends/${this.pageData.user.is_friend.user_friend_id}`
        );
        this.makeToast(
          "success",
          this.$t("g.send.successBody"),
          this.$t("g.send.successTitle")
        );
        this.getProfileData(this.user_id);
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.overlay_loading = false;
      }
    },
    async cancelFriendHandler() {
      try {
        this.overlay_loading = true;
        await this.$http.delete(
          `/student/friend-requests/${this.pageData.user.is_friend_request_pending.friend_request_id}`
        );
        this.makeToast(
          "success",
          this.$t("g.send.successBody"),
          this.$t("g.send.successTitle")
        );
        this.getProfileData(this.user_id);
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.overlay_loading = false;
      }
    },
    filterImages(data, name) {
      const placeholderImage =
        name === "path"
          ? this.profileImgaePlaceholder
          : this.profileCoverPlaceholder;
      if (data) {
        for (let image of data) {
          if (image.name === name) {
            return image.path;
          }
        }
      } else {
        return placeholderImage;
      }
      return placeholderImage;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        this.getProfileData(newValue.params.id);
      },
    },
  },
};
</script>

<style lang="scss">
@import "./showStudent.scss";
</style>
